/* Bad for perf because it loads another file which are blocking */
/* @import url(https://use.typekit.net/kci3htz.css);
@import url(https://use.typekit.net/ids0wcj.css); */

@font-face {
  font-family: "nobel";
  src: url("https://use.typekit.net/af/8c68f7/00000000000000003b9ae84a/27/l?subset_id=2&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/8c68f7/00000000000000003b9ae84a/27/d?subset_id=2&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/8c68f7/00000000000000003b9ae84a/27/a?subset_id=2&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "nobel";
  src: url("https://use.typekit.net/af/2f8a18/00000000000000003b9ae84b/27/l?subset_id=2&fvd=i3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2f8a18/00000000000000003b9ae84b/27/d?subset_id=2&fvd=i3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2f8a18/00000000000000003b9ae84b/27/a?subset_id=2&fvd=i3&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "nobel";
  src: url("https://use.typekit.net/af/9e0ad7/00000000000000003b9ae84c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/9e0ad7/00000000000000003b9ae84c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/9e0ad7/00000000000000003b9ae84c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "nobel";
  src: url("https://use.typekit.net/af/943049/00000000000000003b9ae84d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/943049/00000000000000003b9ae84d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/943049/00000000000000003b9ae84d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "nobel";
  src: url("https://use.typekit.net/af/cab4b1/00000000000000003b9ae84e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/cab4b1/00000000000000003b9ae84e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/cab4b1/00000000000000003b9ae84e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "nobel";
  src: url("https://use.typekit.net/af/2d455d/00000000000000003b9ae84f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2d455d/00000000000000003b9ae84f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2d455d/00000000000000003b9ae84f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "nobel-condensed";
  src: url("https://use.typekit.net/af/7d1590/00000000000000003b9ae855/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/7d1590/00000000000000003b9ae855/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/7d1590/00000000000000003b9ae855/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "nobel-condensed";
  src: url("https://use.typekit.net/af/ae634f/00000000000000003b9ae856/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/ae634f/00000000000000003b9ae856/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/ae634f/00000000000000003b9ae856/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gotham";
  src: url("/fonts/gotham/gothambook.eot");
  src: url("/fonts/gotham/gothambook.eot") format("embedded-opentype"),
    url("/fonts/gotham/gothambook.woff2") format("woff2"),
    url("/fonts/gotham/gothambook.woff") format("woff"),
    url("/fonts/gotham/gothambook.ttf") format("truetype"),
    url("/fonts/gotham/gothambook.svg") format("svg");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("/fonts/gotham/gothammedium.eot");
  src: url("/fonts/gotham/gothammedium.eot") format("embedded-opentype"),
    url("/fonts/gotham/gothammedium.woff2") format("woff2"),
    url("/fonts/gotham/gothammedium.woff") format("woff"),
    url("/fonts/gotham/gothammedium.ttf") format("truetype"),
    url("/fonts/gotham/gothammedium.svg") format("svg");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("/fonts/gotham/gothambookitalic.eot");
  src: url("/fonts/gotham/gothambookitalic.eot") format("embedded-opentype"),
    url("/fonts/gotham/gothambookitalic.woff2") format("woff2"),
    url("/fonts/gotham/gothambookitalic.ttf") format("truetype"),
    url("/fonts/gotham/gothambookitalic.svg") format("svg");
  font-style: italic;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("/fonts/gotham/gothamlight.eot");
  src: url("/fonts/gotham/gothamlight.eot") format("embedded-opentype"),
    url("/fonts/gotham/gothamlight.woff2") format("woff2"),
    url("/fonts/gotham/gothamlight.woff") format("woff"),
    url("/fonts/gotham/gothamlight.ttf") format("truetype"),
    url("/fonts/gotham/gothamlight.svg") format("svg");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "kumlien-pro-condensed";
  src: url("https://use.typekit.net/af/c1229a/00000000000000003b9b388a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/c1229a/00000000000000003b9b388a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/c1229a/00000000000000003b9b388a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "kumlien-pro";
  src: url("https://use.typekit.net/af/7b2d8f/00000000000000003b9b3886/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/7b2d8f/00000000000000003b9b3886/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/7b2d8f/00000000000000003b9b3886/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "kumlien-pro";
  src: url("https://use.typekit.net/af/d55656/00000000000000003b9b3887/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/d55656/00000000000000003b9b3887/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/d55656/00000000000000003b9b3887/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "kumlien-pro";
  src: url("https://use.typekit.net/af/395aed/00000000000000003b9b3889/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/395aed/00000000000000003b9b3889/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/395aed/00000000000000003b9b3889/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

html {
  font-size: 62.5%;
  text-size-adjust: none;
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 1.6rem;
  font-family: Gotham, sans-serif;
  -webkit-font-smoothing: antialiased;
}

#__next {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Nobel, serif;
  font-weight: 400;
  margin-top: 0;
}

p {
  margin-top: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

b {
  font-weight: 500;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

button {
  font-family: Gotham, sans-serif;
  font-size: inherit;
  padding: 0;
  background: none;
  border: none;
  border-radius: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
/* Drip's images are loaded as background images and hidden with visibility hidden, this is not enough to defer the image load */
.drip-hidden {
  display: none !important;
}
